import logo from "./logo.svg";
import React from "react";

function Home() {
  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div id="homeContentContainer">
          <div id="homeContent">
            <h1>Hi, I'm Rowan.</h1>
            <p>
              I'm a software developer and data scientist based in Cambridge.
            </p>

            <p>
              I enjoy creating interactive visualisations to tell interesting
              stories with data. Over the coming months, I hope to use this site
              as a place to publish various pet-projects, past and present!
            </p>
            <p>
              When I'm not working, I enjoy doing ballroom and latin dancing
              competitively, and am a very keen cyclist. (Current LEJOG
              activities can be seen <a href="/lejog">here</a>). I am also an
              occasional piano player, chess player and rower.
            </p>
          </div>
        </div>
      </header>
    </div>
  );
}

export { Home };
